<template>
  <div class="device">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__deviceCertificateManagement") }}</h2>
      <div class="d-flex flex-wrap align-items-center justify-content-start justify-content-md-between">
        <div class="device-select me-2 mb-2">
          <select v-model="filterType" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t("__all") }}</option>
            <option :value="1">
              {{ $t("__bound") + $t("__agency") }}
            </option>
            <option :value="0">
              {{ $t("__unbound") + $t("__agency") }}
            </option>
          </select>
        </div>
        <div
          v-if="filterType !== 0 && isSystemManage" class="d-flex align-items-center me-2 mb-2">
          <div class="device-report-select">
            <select v-model="searchAgent" class="form-select rounded-pill me-2">
              <option :value="null">{{ $t("__all") }}</option>
              <option v-for="item in managers" :value="item.id" :key="'manager'+ item.id">
                {{ item.name + ' / ' + item.contact_name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="filterType !== 0" class="d-flex align-items-center me-2 mb-2">
          <div class="device-report-select">
            <select v-model="searchAgency" class="form-select rounded-pill me-2">
              <option :value="null">{{ $t("__all") + " ( " + $t('__agencyName') + ' / ' + $t('__codeName') + " )"}}</option>
              <option v-for="item in allAgencys" :value="item.id" :key="'agent'+ item.id">
                {{ item.name + ' / ' + item.codename }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-2 mb-2">
          <form @submit.prevent="searchDevice">
            <div class="input-group rounded-pill">
              <input
                v-model="search"
                type="text"
                class="form-control rounded-pill"
                :placeholder="$t('__searchDeviceSerialId')"
                aria-label="search"
                aria-describedby="search"
              />
              <button
                type="submit"
                class="btn btn-link px-2 text-dark"
                id="search"
              >
                <i class="bi bi-search"></i>
              </button>
              <button
                v-if="filter"
                type="button"
                class="btn btn-primary px-3 ms-1 rounded-pill"
                id="clearSearch"
                @click="clearSearch"
              >
                {{ $t("__clearSearch") }}
              </button>
            </div>
          </form>
        </div>
        <div class="me-2 me-auto mb-2">
          <form @submit.prevent="searchAgencyByName">
            <div class="input-group rounded-pill">
              <input
                v-model="searchAgencyKeyword"
                type="text"
                class="form-control rounded-pill"
                :placeholder="$t('__searchAgencyName')"
                aria-label="search"
                aria-describedby="search"
              />
              <button
                type="submit"
                class="btn btn-link px-2 text-dark"
                id="search"
              >
                <i class="bi bi-search"></i>
              </button>
              <button
                v-if="filter"
                type="button"
                class="btn btn-primary px-3 ms-1 rounded-pill"
                id="clearSearch"
                @click="clearSearch"
              >
                {{ $t("__clearSearch") }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3">
        <button
          v-show="!isShowSelected"
          @click="isShowSelected = !isShowSelected"
          class="me-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm mb-2 mb-md-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__select')"
        >
          <i class="bi bi-check2-square"></i>
        </button>
        <button
          v-show="isShowSelected"
          @click="isShowSelected = !isShowSelected"
          class="me-2 fs-5 btn btn-link px-2 rounded-circle lh-sm mb-2 mb-md-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__closeSelect')"
        >
          <i class="bi bi-arrow-left"></i>
        </button>
        <button
          v-show="isShowSelected"
          @click="selectAllRows"
          class="me-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm mb-2 mb-md-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__allSelect')"
        >
          <i class="bi bi-check-all"></i>
        </button>
        <button
          v-show="isShowSelected && selected.length"
          @click="showBatchEditModal"
          class="me-2 btn btn-primary px-2 rounded-circle lh-sm mb-2 mb-md-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__edit')"
        >
          <i class="bi bi-pencil"></i>
        </button>
        <button
          v-show="isShowSelected && selected.length"
          @click="clearSelected"
          class="me-2 fs-5 btn btn-outline-primary px-2 rounded-circle lh-sm mb-2 mb-md-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__clearAll')"
        >
          <i class="bi bi-x"></i>
        </button>
        <div>
          <button
            v-show="!isShowSelected && (isSystemManage || (hasPermission('device.create')))"
            @click="showModal()"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="$t('__add')"
            class="me-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm mb-2 mb-md-0"
          >
            <i class="bi bi-plus"></i>
          </button>
          <button
            v-show="!isShowSelected && isSystemManage"
            @click="batchAddModal()"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="$t('__batchAdd')"
            class="fs-5 btn btn-primary px-2 rounded-circle lh-sm mb-2 mb-md-0"
          >
            <i class="bi bi-folder-plus"></i>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            selectable
            :items="filterItem"
            :fields="fields"
            :sort-desc="sortDesc"
            :sort-by="sortBy"
            select-mode="multi"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
            ref="selectableTable"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(selected)="{ rowSelected }">
              <input
                :checked="rowSelected"
                class="form-check-input"
                type="checkbox"
                disabled
              />
            </template>
            <template v-slot:cell(agency)="data">
              {{
                data.item.agency_id
                  ? data.item.agency.name + " / " + data.item.agency.codename
                  : ""
              }}
            </template>
            <template v-slot:cell(manager)="data">
              <div v-if="isSystemManage && data.item.manager_id !== userInfo.id">
              {{
                data.item.manager_id
                  ? data.item.manager.name + " / " + data.item.manager.username
                  : ""
              }}
              </div>
            </template>
            <template v-slot:cell(heart_rate_enabled)="data">
              {{ data.item.heart_rate_enabled ? $t("__enable") : "" }}
            </template>
            <template v-slot:cell(ended_at)="data">
              {{ data.item.ended_at ? data.item.ended_at : $t("__always") }}
            </template>
            <template v-slot:cell(status)="data">
              <div class="status-switch">
                <input
                  type="checkbox"
                  :id="'switch-' + data.index"
                  v-model="data.item.status"
                  @click="changeStatusAlert(data.item.id)"
                />
                <label :for="'switch-' + data.index"></label>
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  @click="showModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
                <button
                  v-show="isSystemManage && !data.item.agency_id && userInfo.id === data.item.manager_id"
                  @click="deleteDeviceAlert(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-danger"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__delete')"
                >
                  <i class="bi bi-trash fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
            entNub: currentPage == 1
              ? totalRows > perPage
                ? perPage
                : totalRows
              : perPage * (currentPage - 1) + perPage > totalRows
              ? totalRows
              : perPage * (currentPage - 1) + perPage ,
            sum: totalRows
            })
          }}
        </div>
        <div class="col-sm col-12">
          <select class="form-select rounded-pill me-2  mb-2" v-model="perPage">
            <option :value="10">
              {{ '顯示數量／頁：10' }}
            </option>
            <option :value="25">
              {{ '顯示數量／頁：25' }}
            </option>
            <option :value="50">
              {{ '顯示數量／頁：50' }}
            </option>
          </select>
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ isEdit
                    ? $t("__editDevice")
                    : isBatchAdd
                      ? $t("__batchAddDevice")
                      : $t("__addDevice") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3" v-if="isSystemManage">
                <label for="serial_id" class="form-label fw-medium required">{{
                  $t("__deviceId")
                }}</label>
                <input
                  v-if="!isBatchAdd"
                  v-model="modalData.serial_id"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.serial_id
                  }"
                  id="serial_id"
                  required
                  :readonly="isEdit"
                />
                <input
                  v-else
                  v-model="modalData.serial_ids"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.serial_ids
                  }"
                  id="serial_id"
                  required
                />
                <div v-if="isBatchAdd" class="form-text">
                  {{ $t('__batchAddDeviceInfo') }}
                </div>
                <div class="invalid-feedback" v-if="validate && validate.msg.serial_id">
                  <div v-for="(msg, index) in validate.msg.serial_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
                <div class="invalid-feedback" v-if="validate && validate.msg.serial_ids">
                  <div v-for="(msg, index) in validate.msg.serial_ids" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3" v-show="isSystemManage">
                <label for="started_at" class="form-label fw-medium required">{{
                  $t("__deviceStartedAt")
                }}</label>
                <VueCtkDateTimePicker
                  :label="$t('__date')"
                  v-model="modalData.started_at"
                  only-date
                  format="YYYY-MM-DD"
                  formatted="YYYY-MM-DD"
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  id="deviceStartedAt"
                  :class="{
                    'is-invalid': validate && validate.msg.started_at
                  }"
                />
                <div class="invalid-feedback" v-if="validate && validate.msg.started_at">
                  <div v-for="(msg, index) in validate.msg.started_at" :key="index">
                    {{ msg }}
                  </div>
                </div>
                <div v-if="isRequiredToStartedDate" class="form-text text-danger">{{$t("__deviceStartedAtRequired") }}</div>
              </div>
              <div class="mb-3" v-show="isSystemManage">
                <label for="started_at" class="form-label fw-medium required">{{
                  $t("__deviceEndedAt")
                }}</label>
                <VueCtkDateTimePicker
                  :label="$t('__date')"
                  v-model="modalData.ended_at"
                  only-date
                  format="YYYY-MM-DD"
                  formatted="YYYY-MM-DD"
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  id="deviceEndedAt"
                  :class="{
                    'is-invalid': validate && validate.msg.ended_at
                  }"
                />
                <div class="invalid-feedback" v-if="validate && validate.msg.ended_at">
                  <div v-for="(msg, index) in validate.msg.ended_at" :key="index">
                    {{ msg }}
                  </div>
                </div>
                <div v-if="isRequiredToEndedDate" class="form-text text-danger">{{$t("__deviceEndedAtRequired") }}</div>
              </div>
              <div class="mb-3" v-show="isSystemManage">
                <label for="editModal_manager_id" class="form-label fw-medium required">{{
                  $t("__agentAccount") + " / " + $t("__agentName")
                }}</label>
                <select
                  v-model="modalData.manager_id"
                  class="form-select"
                  id="editModal_manager_id"
                  :class="{
                    'is-invalid': validate && validate.msg.manager_id
                  }"
                >
                  <option
                    v-for="manager in managers"
                    :key="'manager' + manager.id"
                    :value="manager.id"
                  >
                    {{ manager.username + " / " + manager.name }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="validate && validate.msg.manager_id">
                  <div v-for="(msg, index) in validate.msg.manager_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="editModal_agency_id" class="form-label fw-medium required">{{
                  $t("__agencyCodename") + " / " + $t("__agencyName")
                }}</label>

                <v-select
                  v-if="agencys !== null"
                  v-model="modalData.agency_id"
                  :options="agencys"
                  label="codename"
                  :reduce="agency => agency.id"
                  :placeholder="$t('__searchAgencyName')"
                  class="style-chooser"
                  :components="{Deselect, OpenIndicator}"
                  :get-option-label="option => `${option.codename} / ${option.name}`"
                  :class="{
                    'is-invalid': validate && validate.msg.agency_id
                  }"
                >
                </v-select>

                <div class="invalid-feedback" v-if="validate && validate.msg.agency_id">
                  <div v-for="(msg, index) in validate.msg.agency_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="editModal_agency_id" class="form-label fw-medium">
                  {{ $t("__enableHeartRate") }}
                </label>
                <input
                  id="editModal_use_haeart_rate"
                  v-model="modalData.heart_rate_enabled"
                  class="form-check-input me-2"
                  type="checkbox"
                />
                <div class="invalid-feedback" v-if="validate && validate.msg.heart_rate_enabled">
                  <div v-for="(msg, index) in validate.msg.heart_rate_enabled" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="batchEdit"
      ref="batchEditModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="batchSubmitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ $t("__batchEditingDevice") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3" v-if="isSystemManage">
                <label for="manager_id" class="form-label fw-medium required">{{
                  $t("__agentAccount") + " / " + $t("__agentName")
                }}</label>
                <select
                  v-model="modalData.manager_id"
                  class="form-select"
                  id="manager_id"
                  :class="{
                    'is-invalid': validate && validate.msg.manager_id
                  }"
                >
                  <option
                    v-for="manager in managers"
                    :key="'manager' + manager.id"
                    :value="manager.id"
                  >
                    {{ manager.username + " / " + manager.name }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="validate && validate.msg.manager_id">
                  <div v-for="(msg, index) in validate.msg.manager_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="agency_id" class="form-label fw-medium required">{{
                  $t("__agencyCodename") + " / " + $t("__agencyName")
                }}</label>
                <select
                  v-model="modalData.agency_id"
                  class="form-select"
                  id="agency_id"
                  :class="{
                    'is-invalid': validate && validate.msg.agency_id
                  }"
                >
                  <option :value="null">
                    {{ $t("__unBoundAgency") }}
                  </option>
                  <option
                    v-for="agency in agencys"
                    :key="'agency' + agency.id"
                    :value="agency.id"
                  >
                    {{ agency.codename + " / " + agency.name }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="validate && validate.msg.agency_id">
                  <div v-for="(msg, index) in validate.msg.agency_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="editModal_agency_id" class="form-label fw-medium">
                  {{ $t("__enableHeartRate") }}
                </label>
                <input
                  id="editModal_use_haeart_rate"
                  v-model="modalData.heart_rate_enabled"
                  class="form-check-input me-2"
                  type="checkbox"
                />
                <div class="invalid-feedback" v-if="validate && validate.msg.heart_rate_enabled">
                  <div v-for="(msg, index) in validate.msg.heart_rate_enabled" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <p class="text-danger">{{ $t('__batchEditingDeviceNote') }}</p>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="changeStatusModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="changeStatusModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__changeStatus") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__changeDeviceNote", {Device: modalData.serial_id}) }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="changeStatus"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="deleteModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__deleteDevice") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__deleteDeviceNote", {Device: modalData.serial_id}) }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="deleteDevice"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { device } from '@/http/api/device.js'
import { agent } from '@/http/api/agent.js'
import { agency } from '@/http/api/agency.js'
import {mapState, mapMutations, mapGetters} from 'vuex'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Device',
  components: { VueCtkDateTimePicker, vSelect },
  data () {
    return {
      sortBy: 'serial_id',
      sortDesc: false,
      agencys: null,
      allAgencys: null,
      managers: null,
      items: null,
      filterItem: null,
      filter: null,
      filterType: null,
      isShowSelected: false,
      selected: [],
      filterOn: ['serial_id', 'agency'],
      search: null,
      searchAgency: null,
      searchAgent: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      isEdit: false,
      isBatchAdd: false,
      editModalId: null,
      modalData: {
        serial_ids: null,
        serial_id: null,
        deviceIds: [],
        status: null,
        started_at: null,
        ended_at: null,
        agency_id: null,
        manager_id: null,
        heart_rate_enabled: false
      },
      isRequiredToStartedDate: false,
      isRequiredToEndedDate: false,
      validate: null,
      searchAgencyKeyword: null,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', [
          createElement('img', {
            attrs: {
              src: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"
            },
            style: {
              width: '16px',
              height: '16px',
            }
          })
        ]),
      },
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    ...mapGetters(['hasPermission', 'isSystemManage']),
    permissions () {
      let permissions = null
      if (this.userInfo) {
        permissions = this.userInfo.permissions
      }
      return permissions
    },
    fields () {
      return [
        {
          key: this.isShowSelected ? 'selected' : null,
          label: i18n.t('__batchEditing'),
          class: 'align-middle text-center',
          sortable: false
        },
        {
          key: 'serial_id',
          label: i18n.t('__deviceId'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'agency',
          label: i18n.t('__agencyName') + ' / ' + i18n.t('__codeName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: this.isSystemManage ? 'manager' : null,
          label: i18n.t('__agentName') + ' / ' + i18n.t('__account'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'heart_rate_enabled',
          label: i18n.t('__heartRate'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'started_at',
          label: i18n.t('__deviceStartedAt'),
          class: 'align-middle text-center',
          sortable: true
        },
        {
          key: 'ended_at',
          label: i18n.t('__deviceEndedAt'),
          class: 'align-middle text-center',
          sortable: true
        },
        {
          key: this.isShowSelected ? null : 'status',
          label: i18n.t('__status'),
          class: 'align-middle text-center',
          sortable: false
        },
        {
          key: this.isShowSelected ? null : 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle',
          sortable: false
        }
      ]
    }
  },
  watch: {
    items (data) {
      const vm = this
      if (data) {
        vm.filterItem = vm.items
        vm.initBootstrapTooltip()
      }
    },
    filterItem (data) {
      if (data) {
        this.totalRows = data.length
      } else {
        this.totalRows = 0
      }
      this.currentPage = 1
    },
    isShowSelected (data) {
      this.hideTooltip()
      this.initBootstrapTooltip()
      if (!data) {
        this.clearSelected()
      }
    },
    filterType (data) {
      switch (data) {
        case null:
          if (this.searchAgency) {
            this.filterItem = this.items.filter(item => item.agency_id && this.searchAgency === item.agency_id)
          } else if (this.searchAgent) {
            this.filterItem = this.items.filter(item => item.agency && data === item.agency.manager_id)
          } else {
            this.filterItem = this.items
          }
          break
        case 0:
          this.filterItem = this.items.filter(item => !item.agency_id)
          this.searchAgency = null
          break
        case 1:
          this.filterItem = this.items.filter(item => {
            if (this.searchAgency) {
              return item.agency_id && this.searchAgency === item.agency_id
            } else {
              return item.agency_id
            }
          })
          break
      }
    },
    searchAgency (data) {
      if (data) {
        this.filterItem = this.items.filter(item => data === item.agency_id)
      } else if (this.searchAgent){
        this.filterItem = this.items.filter(item => item.agency && this.searchAgent === item.agency.manager_id)
      } else {
        this.filterItem = this.items
      }
    },
    searchAgent (data) {
      if (data) {
        this.filterItem = this.items.filter(item => item.agency && data === item.agency.manager_id)
      } else if (this.searchAgency){
        this.filterItem = this.items.filter(item => this.searchAgency === item.agency_id)
      } else {
        this.filterItem = this.items
      }
    },
    'modalData.manager_id': function (data) {
      this.getAgency(data)
    },
    'modalData.started_at': function () {
      this.isRequiredToStartedDate = false
    },
    'modalData.ended_at': function () {
      this.isRequiredToEndedDate = false
    },
    userInfo (data) {
      if (data) {
        this.modalData.manager_id = data.id
      }
    },
    modalData: {
      handler: function () {
        this.validate = null
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getAgency (managerId) {
      const vm = this
      var config = Object.assign({}, this.token)
      config.params = {
        managerId: managerId
      }
      agency.get('', config).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.agencys = res.data.data

          const exists = vm.agencys.some(agency => agency.id === this.modalData.agency_id)
          if (!exists) {
            this.modalData.agency_id = null
          }
        }
      })
    },
    getAllAgency () {
      const vm = this
      agency.get('', this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.allAgencys = res.data.data
        }
      })
    },
    getManager () {
      const vm = this
      agent.get('', vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.managers = res.data.data
        }
      })
    },
    getDevice () {
      const vm = this
      vm.Loading()
      device
        .get('', vm.token)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.items = res.data.data
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.$refs.selectableTable.clearSelected()
    },
    onRowSelected (items) {
      this.selected = items
    },
    changeStatusAlert (id) {
      const vm = this
      vm.editModalId = id
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.serial_id = item.serial_id
          vm.modalData.status = !item.status
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.changeStatusModal)
      vm.bootstrap_modal.show()
      vm.$refs.changeStatusModal.addEventListener('hidden.bs.modal', () => {
        vm.getDevice()
      })
    },
    changeStatus () {
      const vm = this
      vm.Loading()
      device
        .changeStatus(
          vm.editModalId,
          {
            status: vm.modalData.status
          },
          vm.token
        )
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__changeStatus') + i18n.t('__success')
            })
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
          }
          vm.Loaded()
          vm.Alerted()
          vm.bootstrap_modal.hide()
        })
    },
    searchDevice () {
      this.filter = this.search
    },
    searchAgencyByName () {
      this.filter = this.searchAgencyKeyword;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearSearch () {
      this.filter = null
      this.search = null
      this.searchAgencyKeyword = null
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    showModal (id) {
      const vm = this
      vm.resetModalData()
      vm.editModalId = id
      if (id) {
        vm.isEdit = true
        vm.items.forEach((item) => {
          if (item.id === id) {
            vm.modalData.serial_id = item.serial_id
            vm.modalData.status = item.status
            vm.modalData.started_at = item.started_at
            vm.modalData.ended_at = item.ended_at
            vm.modalData.agency_id = item.agency_id
            vm.modalData.manager_id = item.manager_id
            vm.modalData.heart_rate_enabled = item.heart_rate_enabled
          }
        })
      } else {
        vm.isEdit = false
      }
      vm.bootstrap_modal = new Modal(vm.$refs.editModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    batchAddModal () {
      const vm = this
      vm.resetModalData()
      vm.isBatchAdd = true
      vm.editModalId = null
      vm.bootstrap_modal = new Modal(vm.$refs.editModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    resetModalData () {
      this.modalData.serial_ids = null
      this.modalData.serial_id = null
      this.modalData.deviceIds = []
      this.modalData.status = null
      this.modalData.started_at = null
      this.modalData.ended_at = null
      this.modalData.agency_id = null
      this.modalData.manager_id = this.userInfo.id
      this.modalData.heart_rate_enabled = false

      this.isBatchAdd = false
    },
    submitForm () {
      const vm = this
      if (!this.modalData.started_at) {
        vm.isRequiredToStartedDate = true
        return
      }
      if (!this.modalData.ended_at) {
        vm.isRequiredToEndedDate = true
        return
      }
      vm.Loading()
      if (vm.isBatchAdd) {
        const serialIds = vm.modalData.serial_ids.split(',')
        device
          .createBatch(
            {
              serial_ids: serialIds,
              started_at: vm.modalData.started_at,
              ended_at: vm.modalData.ended_at,
              agency_id: vm.modalData.agency_id,
              manager_id: vm.modalData.manager_id,
              heart_rate_enabled: vm.modalData.heart_rate_enabled
            },
            vm.token
          ).then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__createSuccess')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.validate = res.data.validate
            }
            vm.Loaded()
            vm.Alerted()
          })
      } else if (vm.editModalId) {
        device
          .update(
            vm.editModalId,
            {
              serial_id: vm.modalData.serial_id,
              started_at: vm.modalData.started_at,
              ended_at: vm.modalData.ended_at,
              agency_id: vm.modalData.agency_id,
              manager_id: vm.modalData.manager_id,
              heart_rate_enabled: vm.modalData.heart_rate_enabled
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
            }
            vm.Loaded()
            vm.Alerted()
          })
      } else {
        device
          .create(
            {
              serial_id: vm.modalData.serial_id,
              started_at: vm.modalData.started_at,
              ended_at: vm.modalData.ended_at,
              agency_id: vm.modalData.agency_id,
              manager_id: vm.modalData.manager_id,
              heart_rate_enabled: vm.modalData.heart_rate_enabled
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__createSuccess')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
            }
            vm.Alerted()
            vm.Loaded()
          })
      }
    },
    showBatchEditModal () {
      const vm = this
      vm.resetModalData()
      vm.isEdit = true

      vm.selected.forEach((item) => {
        vm.modalData.deviceIds.push(item.id)
      })

      vm.bootstrap_modal = new Modal(vm.$refs.batchEditModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    batchSubmitForm () {
      const vm = this
      vm.Loading()
      device
        .updateBatch(
          {
            deviceIds: vm.modalData.deviceIds,
            agency_id: vm.modalData.agency_id,
            manager_id: vm.modalData.manager_id,
            heart_rate_enabled: vm.modalData.heart_rate_enabled
          },
          vm.token
        )
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getDevice()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
          }
          vm.Loaded()
          vm.Alerted()
        })
    },
    deleteDeviceAlert (id) {
      const vm = this
      vm.editModalId = id
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.serial_id = item.serial_id
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.deleteModal)
      vm.bootstrap_modal.show()
      vm.$refs.changeStatusModal.addEventListener('hidden.bs.modal', () => {
        vm.getDevice()
      })
    },
    deleteDevice () {
      const vm = this
      device
        .delete(
          vm.editModalId,
          vm.token
        )
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__deleteDevice') + i18n.t('__success')
            })
            vm.getDevice()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
          }
          vm.Alerted()
          vm.Loaded()
          vm.bootstrap_modal.hide()
        })
    }
  },
  created () {
    this.getDevice()
    this.getManager()
    this.getAllAgency()
  },
  mounted () {}
}
</script>

<style  lang="scss">
  .form-check-input {
    margin-top: 3px;
    margin-left: 10px;
  }

  .style-chooser {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 0.1rem 0.35rem 0.1rem 0.15rem;
    --vs-controls-color: #4F5258;
    --vs-controls-size: 0.8;
    --vs-controls--deselect-text-shadow: 0 1px 0 #fff;

    .vs__search::placeholder,
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      background: #ffffff;
      border: none;
      color: #000;
    }
  }
</style>
